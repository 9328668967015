<!--
   @Author:JYD
   @Date: 2021/12/31
   @Description:平台门户首页底部轮播
 -->
<template>
  <article class="container">
    <section class="colleges" style="padding-bottom: 84px;">
      <div class="content-box">
        <div class="title"><span>部分入驻企业</span></div>
        <div style="position: relative;">
          <div style="position: absolute; top: 0px; left: 0; z-index: 99;"><img src="@/assets/images/Occlusion.png" class="heiaaa" alt="" style="transform: rotate(180deg);"></div>
          <div style="position: absolute; top: 0px; right: 0; z-index: 99;"><img src="@/assets/images/Occlusion.png" class="heiaaa" alt=""></div>
          <div v-if="companyRecords.swiperDataA.length>0" ref="mySwiper" class="swiper mySwiper">
            <ul class="swiper-wrapper" style="display: flex;">

              <li v-for="(item,index) in companyRecords.swiperDataA" :key="index" class="swiper-slide">
                <div class="cardBox">
                  <img :src="item.enterLogo" alt="" style="height: 74px;">
                  <!-- {{ item.enterName }} -->
                </div>
              </li>
              <!-- <li class="swiper-slide">
                <img src="@/assets/images/hxplatform.png" alt="" style="height: 74px;">
              </li> -->
            </ul>
          </div>
          <div v-if="companyRecords.swiperDataB.length>0" ref="mySwiper" class="swiper mySwiper1" style="margin-top: 25px;">
            <ul class="swiper-wrapper" style="display: flex;">
              <!-- <li class="swiper-slide">
                <img src="@/assets/images/hxplatform.png" alt="" style="height: 93px;">
              </li>
              <li class="swiper-slide">
                <img src="@/assets/images/hxplatform.png" alt="" style="height: 93px;">
              </li> -->
              <li v-for="(item,index) in companyRecords.swiperDataB" :key="index" class="swiper-slide">
                <div class="cardBox">
                  <img :src="item.enterLogo" alt="" style="height: 93px;">
                  <!-- {{ item.enterName }} -->
                </div>
              </li>
            </ul>
          </div>
          <div v-if="companyRecords.swiperDataC.length>0" ref="mySwiper" class="swiper mySwiper" style="margin-top: 5px;">
            <ul class="swiper-wrapper" style="display: flex;">
              <!-- <li class="swiper-slide">
                <img src="@/assets/images/hxplatform.png" alt="" style="height: 74px;">
              </li>
              <li class="swiper-slide">
                <img src="@/assets/images/hxplatform.png" alt="" style="height: 74px;">
              </li> -->
              <li v-for="(item,index) in companyRecords.swiperDataC" :key="index" class="swiper-slide">
                <div class="cardBox">
                  <img :src="item.enterLogo" alt="" style="height: 74px;">
                  <!-- {{ item.enterName }} -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>
<script>
// import Swiper from 'swiper/swiper-bundle.min.js'
import Swiper from '@/components/swiper/swiper-bundle.min.js'
import 'swiper/swiper-bundle.min.css'
export default {
  layout: 'plat-portal',
  // props: {
  //   companyRecords: {
  //     type: Object,
  //     default() {
  //       return {}
  //     }
  //   }
  // },
  data() {
    return {
      companyRecords: {
        swiperDataA: [],
        swiperDataB: [],
        swiperDataC: []
      }
    }
  },
  mounted() {
    this.companyRequestData()
    // this.$nextTick(() => {
    //   const swiper = this.$refs.mySwiper
    //   swiper.children[0].style['transition-timing-function'] = 'linear'
    //   this.initSwiper()
    //   this.initSwiper1()
    // })
  },
  methods: {
    // 企业列表获取
    companyRequestData() {
      this.$get(this.urls.companyRecommendList)
        .then((result) => {
          if (result.code === 'SUCCESS') {
            const data = result.data
            const value = Math.trunc(data.length / 3.3)
            this.companyRecords.swiperDataA = data.slice(0, value + 1)
            this.companyRecords.swiperDataB = data.slice(value + 1, 2 * value + 2)
            this.companyRecords.swiperDataC = data.slice(2 * value + 2)
            // this.companyRecords = data.records
            // console.log(this.companyRecords)
            this.$nextTick(() => {
              const swiper = this.$refs.mySwiper
              swiper.children[0].style['transition-timing-function'] = 'linear'
              this.initSwiper()
              this.initSwiper1()
            })
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((err) => {
          console.log('err==', err)
        })
    },
    initSwiper() {
      new Swiper('.mySwiper', {
        slidesPerView: 'auto',
        spaceBetween: 22,
        loop: true,
        speed: 18000,
        autoplay: {
          delay: 1, // 自动切换的时间间隔
          disableOnInteraction: false
        }
      })
    },
    initSwiper1() {
      new Swiper('.mySwiper1', {
        slidesPerView: 'auto',
        spaceBetween: 9,
        loop: true,
        speed: 23000,
        autoplay: {
          delay: 1, // 自动切换的时间间隔
          disableOnInteraction: false
        }
      })
    }
  }

}

</script>

<style scoped lang="scss">
.container {
  width: 100%;

  .content-box {
    // width: 1280px;
    height: 100%;
    margin: 0 auto;
  }

  .colleges {
    width: 100%;

    .swiper-slide {
      width: unset;
    }
  }

  .foot {
    width: 100%;
    height: 289px;
    background-color: #1d2129;

    .foot-box {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      padding-top: 54px;
    }
  }

  .foot-font-title {
    margin-bottom: 12px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .foot-font {
    color: #9c9da1;
    font-size: 12px;
    line-height: 24px;
  }
}
.cardBox {
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: start;
  img {
    width: 145px;
    // height: 50px;
    object-fit: contain;
    margin: 0 10px 0 0;
  }
}
.title {
  text-align: center;
  padding: 30px 0px 30px 0px;
  width: 100%;
  line-height: 46px;
  font-size: 24px;
  // font-weight: bold;
  font-weight: normal;
  color: #252b3a;
  font-family: Hiragino Sans GB, Helvetica Neue, Helvetica, STHeiTi,
    Microsoft Yahei, sans-serif;
}

.title span {
  position: relative;
}

.title span:after {
  position: absolute;
  content: " ";
  left: -16px;
  top: 50%;
  margin-top: -10px;
  width: 4px;
  height: 20px;
  background: linear-gradient(135deg, #2791ff 0, #03a7ff 98%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.heiaaa {
  height: 367px;
}
</style>
