<!--
   @Author:JYD
   @Date: 2021/12/31
   @Description:平台门户首页
 -->
<template>
  <article class="container">
    <div>
      <div ref="rotationpic" class="swiper rotationpic">
        <ul class="swiper-wrapper">
          <li v-for="(item,index) in postList" :key="index" class="swiper-slide">
            <div>
              <div class="postName" :title="item.jobname">
                {{ item.jobname }}
              </div>
              <div class="postPrice">{{ item.salaryPackage }}</div>
              <div class="eltag">
                <!-- <el-tag v-if="formattingField(dictObj.education,item.education,'dictCode','dictValue')" v-once type="info">{{
                  formattingField(
                    dictObj.education,
                    item.education,
                    "dictCode",
                    "dictValue"
                  )
                }}</el-tag> -->
                <el-tag v-if="item.education" v-once type="info">{{ item.education }}</el-tag>
                <el-tag v-if="item.workYear" type="info">{{
                  item.workYear
                }}</el-tag>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>
<script>
import Swiper from '@/components/swiper/swiper-bundle.min.js'
import '@/components/swiper/swiper.css'
let vm = null
export default {
  name: 'Verticalswiper',
  data() {
    return {
      postList: [],
      dictObj: {},
      financialStageList: [],
      industryList: []
    }
  },
  created() {
    vm = this

    this.getDictObj()
  },
  mounted() {

  },
  methods: {
    toPost(vm, index) {
      // const index = vm.clickedIndex - vm.activeIndex + vm.realIndex === 6 ? 0 : vm.clickedIndex - vm.activeIndex + vm.realIndex
      const data = vm.postList[index]
      this.$router.push(
        {
          path: '/recruitment',
          query: {
            pageFlag: 2,
            postId: data.sid
          }
        }
      )
    },
    // 企业列表获取
    requestData() {
      this.$get(this.urls.postRecommendList, this.searchForm, [1, 6])
        .then((result) => {
          if (result.code === 'SUCCESS') {
            result.data.records.forEach(item => {
              item.education = this.formattingField(this.dictObj.education, item.education, 'dictCode', 'dictValue')
            })
            this.postList = result.data.records
            this.$nextTick(() => {
              const swiper = this.$refs.rotationpic
              swiper.children[0].style['transition-timing-function'] = 'linear'
              this.initSwiper()
            })
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((err) => {
          console.log('err==', err)
        })
    },
    // 获取字典数据
    getDictObj() {
      this.$get(`${this.urls.dicValues}`, {
        types: 'education,enter_industry,enterprise_scale'
      })
        .then((result) => {
          if (result.code === 'SUCCESS') {
            this.requestData()
            this.dictObj = result.data
            this.industryList = this.dictObj.enter_industry
            this.financialStageList = this.dictObj.enterprise_scale
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    },
    initSwiper() {
      new Swiper('.rotationpic', {
        slidesPerView: '5',
        spaceBetween: 22,
        loop: true,
        direction: 'vertical',
        speed: 2000,
        autoplay: {
          delay: 0, // 自动切换的时间间隔
          disableOnInteraction: false
        },
        on: {
          click: function() {
            // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
            // console.log(this, vm) // -> Swiper
            // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
            const index = this.clickedIndex - this.activeIndex + this.realIndex === 6 ? 0 : this.clickedIndex - this.activeIndex + this.realIndex
            vm.toPost(vm, index)
          }
        }
      })
    },
    formattingField(list, codeValue, codeKey, valueKey) {
      if (!list || !codeValue) {
        return codeValue
      }
      const match = list.find(item => {
        if (item[codeKey] === codeValue) {
          return true
        }
      })
      return match ? match[valueKey] : ''
    }
  }

}

</script>

<style scoped lang="scss">
// .container {
//   width: 100%;

//   .content-box {
//     width: 1280px;
//     height: 100%;
//     margin: 0 auto;
//   }

//   .colleges {
//     width: 100%;

//     .swiper-slide {
//       width: unset;
//     }
//   }

//   .foot {
//     width: 100%;
//     height: 289px;
//     background-color: #1d2129;

//     .foot-box {
//       display: flex;
//       align-items: flex-start;
//       justify-content: space-around;
//       padding-top: 54px;
//     }
//   }

//   .foot-font-title {
//     margin-bottom: 12px;
//     color: #fff;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 20px;
//   }

//   .foot-font {
//     color: #9c9da1;
//     font-size: 12px;
//     line-height: 24px;
//   }
// }
// .cardBox{
//   height: 74px;
//   display: flex;
//   align-items: center;
//   justify-content: start;
// img{
//   width: 50px;
//   // height: 50px;
//   margin: 0 10px 0 0;
// }
// }
.swiper{
      position: relative;
    width: 100%;
    height: 600px;
}
.swiper-slide{
      background: #ffffffeb;
      background: #ffffff;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 4px;
}
.swiper-wrapper {
    padding: 0 15px;
    box-sizing: border-box;
}
.postName {
  height: 28px;
  line-height: 28px;
  color: #252b3a;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
}
.postPrice {
  color: #c7000b;
  text-align: right;
}
.eltag{
  span{
margin-right: 10px;
  }

}
</style>
