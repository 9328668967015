<!--
   User: Liu Yin
   Date: 2020/3/5
   Description:
 -->
<template>
  <div id="home">
    <article style="position: relative;background-color: #f5f6f8;">
      <article>
        <swiperbanner class="banner" :dict-code="Banner.BANNER_HOME"></swiperbanner>
        <section class="statistics">
          <div class="content-box statistics-box">
            <!-- <div>
              <div style="margin-bottom: 6px; color: #000; font-size: 16px">
                测评数量
              </div>
              <div style="color: #000; font-size: 20px">
                <span
                  style="padding-right: 4px; font-weight: 500; font-size: 39px"
                >{{ homePageData.assessmentCount }}</span
                >套
              </div>
            </div> -->
            <div>
              <svg-icon icon-class="qiye" class="iconweight"></svg-icon>
              <div style="display:inline-block">
                <div style="margin-bottom: 6px; color: #000; font-size: 16px">
                  企业数量
                </div>
                <div style="color: #000; font-size: 20px">
                  <span style="padding-right: 4px; font-weight: 500; font-size: 39px" class="numDiv">
                    <count-to :start-val="0" :end-val="homePageData.enterpriseCount" :duration="1000" class="card-panel-num" />
                  </span>家
                </div>
              </div>
            </div>
            <div>

              <svg-icon icon-class="gangwei" class="iconweight"></svg-icon>
              <div style="display:inline-block">
                <div style="margin-bottom: 6px; color: #000; font-size: 16px">
                  岗位数量
                </div>
                <div style="color: #000; font-size: 20px">
                  <span style="padding-right: 4px; font-weight: 500; font-size: 39px" class="numDiv">
                    <count-to :start-val="0" :end-val="homePageData.jobCount" :duration="1000" class="card-panel-num" />
                  </span>个
                </div>
              </div>
            </div>
            <div>
              <svg-icon icon-class="kecheng" class="iconweight"></svg-icon>
              <div style="display:inline-block">
                <div style="margin-bottom: 6px; color: #000; font-size: 16px">
                  课程数量
                </div>
                <div style="color: #000; font-size: 20px">
                  <span style="padding-right: 4px; font-weight: 500; font-size: 39px" class="numDiv">
                    <count-to :start-val="0" :end-val="homePageData.videoCount" :duration="1000" class="card-panel-num" />
                  </span>门
                </div>
              </div>
            </div>
            <div>
              <svg-icon icon-class="jianli" class="iconweight"></svg-icon>
              <div style="display:inline-block">
                <div style="margin-bottom: 6px; color: #000; font-size: 16px">
                  简历数量
                </div>
                <div style="color: #000; font-size: 20px">
                  <span style="padding-right: 4px; font-weight: 500; font-size: 39px" class="numDiv">
                    <count-to :start-val="0" :end-val="homePageData.resumeCount" :duration="1000" class="card-panel-num" />
                  </span>份
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="employmentService">
            <div class="maxCenter">
              <div class="title"><span>一站式综合服务</span></div>
              <div class="navbox flexcenbet">
                <div class="navleft">
                  <ul>
                    <li v-for="(val,num) in oneStopList " :key="num" :class="isHover===num ? 'isHover' : ''" @mouseover="handleFover(num)">
                      <svg-icon class="svgicon" :icon-class="val.svg"></svg-icon>
                      {{ val.title }}
                    </li>
                  </ul>
                </div>
                <!-- <div v-for="(item,index) in pictrue" :key="index" class="navright">
                  <img :src="item.pic" alt=""> -->
                <!-- </div> -->
                <!-- <img :src="pictrue[isHover].pic" class="pictrue" alt=""> -->
                <div class="bgdetails" :style="'background:linear-gradient(rgba(245, 246, 248, 0.8) 100%, rgba(245, 246, 248, 0.8) 100%),url('+pictrue[isHover].pic+')'" v-html="oneStopList[isHover].content"></div>
              </div>
            </div>
          </div>
        </section>
        <section class="">
          <div class="title"><span>精准匹配 高效入职</span></div>
          <div class="flexbet card">
            <el-card class="left">
              <div class="box-card">
                <ul>
                  <li>岗位招聘</li>
                </ul>
              </div>
              <div>
                <verticalswiper class="verticalCard"></verticalswiper>
              </div>
            </el-card>
            <el-card class="right">
              <div class="box-card">
                <ul>
                  <li :class="isActive===2 ? 'active' : ''" @click="handleChange(2)">在岗培训</li>
                  <li :class="isActive===3 ? 'active' : ''" @click="handleChange(3)">人才就业</li>
                </ul>
                <div v-for="(item,index) in changeList" :key="index" class="cardcontent">
                  <div class="postname">{{ item.title }}</div>
                  <div class="postcontent">{{ item.introduce }}</div>
                  <el-tag v-if="item.tag" type="info" effect="plain">
                    {{ item.tag }}
                  </el-tag>
                  <p v-if="item.deliver" class="deliver">交付方式：{{ item.deliver }}</p>
                  <div v-if="item.price" class="price">
                    ￥{{ item.price }}
                    <span> {{ item.priceUnit }}</span>
                  </div>
                  <!-- <div class="price">
                    费用面议
                  </div> -->
                </div>
              </div>
            </el-card>
          </div>
        </section>
        <autoswiper class="autoswiper" :company-records="companyRecords"></autoswiper>
        <!-- <autoswiper class="autoswiper" :company-records="companyRecords"></autoswiper>
        <autoswiper class="autoswiper" :company-records="companyRecords"></autoswiper> -->
      </article>
    </article>

  </div>
</template>

<script>
import listMixin from '../../../../utils/mixins/listMixin'
import Swiperbanner from '../../../../components/swiperbanner.vue'
import { Banner } from '../../../../utils/dict'
import Autoswiper from '../../../../components/autoswiper.vue'
import verticalswiper from './components/verticalswiper.vue'
import CountTo from 'vue-count-to'
export default {
  components: {
    Swiperbanner,
    Autoswiper,
    verticalswiper,
    CountTo
  },
  mixins: [listMixin],
  data() {
    return {
      Banner,
      bgImage: require('@/assets/images/one_A.png'),
      homePageData: {
        enterpriseCount: 0,
        jobCount: 0,
        videoCount: 0,
        resumeCount: 0
      },
      companyRecords: {
        swiperDataA: [],
        swiperDataB: [],
        swiperDataC: []
      },
      isActive: 2,
      isHover: 0,
      changeList: [],
      oneStopList: [
        {
          svg: 'jiejuenandian',
          title: '解决企业适岗人才招聘难点',
          content: ''
        },
        {
          svg: 'zhuanyerenyuan',
          title: '多年专业经验产业人才培养，企业人才应用服务',
          content: ''
        },
        {
          svg: 'guihua',
          title: '一站式培养，到岗培训，职业规划',
          content: ''
        },
        {
          svg: 'damuzhi',
          title: '客户信赖，行业认可',
          content: ''
        },
        {
          svg: 'baozhang',
          title: '人力资源专家团队及资深顾问保障',
          content: ''
        }
      ],
      pictrue: [
        { pic: require('@/assets/images/one_A.png') },
        { pic: require('@/assets/images/one_B.png') },
        { pic: require('@/assets/images/one_C.png') },
        { pic: require('@/assets/images/one_D.png') },
        { pic: require('@/assets/images/one_E.png') }
      ],
      // 在岗培训
      onboardingTraining: [
        {
          title: '岗前培训',
          introduce: '制定岗前培训方案，为新员工提供正确的、相关的工作信息和技能，快速适应新环境',
          tag: '定制服务',
          price: '3000',
          deliver: '人工服务',
          priceUnit: '/次起'
        },
        {
          title: '员工内训',
          introduce: '定制化培养企业文化，培训员工基础技能型操作，提升员工工作效率',
          tag: '精准服务',
          price: '5000',
          deliver: '人工服务',
          priceUnit: '/元起'
        },
        {
          title: '职业规划',
          introduce: '通过职业定位、目标设定和通道设计为职业人进行系统的设计职业历程',
          tag: '咨询服务',
          price: '988',
          deliver: '人工服务',
          priceUnit: '/次起'
        },
        {
          title: '名企实习',
          introduce: '评估个人资质、技能、综合能力，推荐入职实习',
          tag: '精准服务',
          price: '2000',
          deliver: '人工服务',
          priceUnit: '/次起'
        },
        {
          title: '求职精讲',
          introduce: '根据学院情况定制课程，在职导师1对1深度辅导',
          tag: '咨询服务',
          price: '388',
          deliver: '人工服务',
          priceUnit: '/次起'
        },
        {
          title: '实习准备',
          introduce: '从拿到offer，解决入职失误，助力成为抢手人才',
          tag: '咨询服务',
          price: '8888',
          deliver: '人工服务',
          priceUnit: '/次起'
        }

      ],
      // 人才就业
      talentEmployment: [
        {
          title: '校园招聘会',
          introduce: '围绕宣讲会,openDay的需求，向行业企业广泛推荐多层次的应届毕业生',
          tag: '走进校园',
          price: '8000',
          deliver: '人工服务',
          priceUnit: '/次起'
        },
        {
          title: '社会招聘',
          introduce: '快速帮助企业解决招聘难题，降低招人成本，快速筛选、精准匹配到岗',
          tag: '便捷高效',
          price: '500',
          deliver: '人工服务',
          priceUnit: '/次起'
        },
        {
          title: '简历精修',
          introduce: '结合求职目标，梳理过往经历，打造专业中英文简历',
          tag: '个人服务',
          price: '300',
          deliver: '人工服务',
          priceUnit: '/人次起'
        },
        {
          title: 'OFFER谈判',
          introduce: '谈判前的准备，谈判过程的把控，如何决策',
          tag: '咨询服务',
          price: '500',
          deliver: '人工服务',
          priceUnit: '/次起'
        },
        {
          title: '笔/面试辅导',
          introduce: '目标行业名企往年真题，目标公司在职实战模拟',
          tag: '个人服务',
          price: '1000',
          deliver: '人工服务',
          priceUnit: '/次起'
        },
        {
          title: '全职内推',
          introduce: '企业内部资源推荐，快速响应',
          tag: '个人服务',
          price: '5000',
          deliver: '人工服务',
          priceUnit: '/次起'
        }
      ]

    }
  },

  mounted() {
    this.searchForm.type = '1'
    document.body.scrollTop = 0
    this.gethomeData()
    this.getEmploymentData()
    this.getProvisionData()
    // this.companyRequestData()
    // this.changeList = this.onboardingTraining
  },
  activated() {
    this.scrollToPosition()
    document.getElementById('app').scrollTop = 0
  },
  methods: {
    getProvisionData() {
      this.$get(this.urls.getProvision, this.searchForm)
        .then((result) => {
          if (result.code === 'SUCCESS') {
            if (result.data.length > 0) {
              this.changeList = result.data
            } else {
              this.changeList = this.searchForm.type === '1' ? this.onboardingTraining : this.talentEmployment
            }
            this.$forceUpdate()
          } else {
            this.changeList = this.searchForm.type === '1' ? this.onboardingTraining : this.talentEmployment
            // this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.changeList = this.searchForm.type === '1' ? this.onboardingTraining : this.talentEmployment
        })
    },
    getEmploymentData() {
      this.$get(this.urls.getEmployment)
        .then((result) => {
          if (result.code === 'SUCCESS') {
            // 待插入的字符串
            const strings = ['jiejuenandian', 'zhuanyerenyuan', 'guihua', 'damuzhi', 'baozhang']
            const Pics = [require('@/assets/images/one_A.png'), require('@/assets/images/one_B.png'), require('@/assets/images/one_C.png'), require('@/assets/images/one_D.png'), require('@/assets/images/one_E.png')]
            for (let i = 0; i < result.data.length; i++) {
              const randomString = strings[i % strings.length]
              const randomPic = Pics[i % Pics.length]
              result.data[i]['svg'] = randomString
              if (i >= 5) {
                this.pictrue.push({ 'pic': randomPic })
              }
            }
            if (result.data.length > 0) {
              this.oneStopList = result.data
            }

            this.$forceUpdate()
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => { })
    },
    handleChange(num) {
      this.isActive = num
      if (num === 2) {
        this.searchForm.type = '1'
        this.getProvisionData()
      } else {
        this.searchForm.type = '2'
        this.getProvisionData()
      }
    },
    handleFover(num) {
      this.isHover = num
      // if (num === 0) {
      //   this.bgImage = require('@/assets/images/one_A.png')
      // } else if (num === 1) {
      //   this.bgImage = require('@/assets/images/one_B.png')
      // } else if (num === 2) {
      //   this.bgImage = require('@/assets/images/one_C.png')
      // } else if (num === 3) {
      //   this.bgImage = require('@/assets/images/one_D.png')
      // } else if (num === 4) {
      //   this.bgImage = require('@/assets/images/one_E.png')
      // }
    },
    gethomeData() {
      this.$get(this.urls.portalhome)
        .then((result) => {
          if (result.code === 'SUCCESS') {
            this.homePageData = result.data
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => { })
    }
    // 企业列表获取
    // companyRequestData() {
    //   this.$get(this.urls.companyRecommendList)
    //     .then((result) => {
    //       if (result.code === 'SUCCESS') {
    //         const data = result.data.records
    //         const value = Math.trunc(data.length / 3.3)
    //         this.companyRecords.swiperDataA = data.slice(0, value + 1)
    //         this.companyRecords.swiperDataB = data.slice(value + 1, 2 * value + 2)
    //         this.companyRecords.swiperDataC = data.slice(2 * value + 2)
    //         // this.companyRecords = data.records
    //         // console.log(this.companyRecords)
    //       } else {
    //         this.$message.error(result.msg)
    //       }
    //     })
    //     .catch((err) => {
    //       console.log('err==', err)
    //     })
    // }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 430px;
}

.statistics-box {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  // padding: 0 100px;
}
.statistics {
  background: #fdfdfd;
  height: 94px;
  width: 100%;
}
.content-box.statistics-box {
  height: 100%;
  margin: 0 auto;
  max-width: 1280px;
}
.box-card {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    li {
      padding: 20px;
      text-align: center;
      width: 100%;
      color: #212a40;
      font-size: 20px;
      cursor: pointer;
    }

    .active {
      background: #ecf6ff;
      color: #212a40;
    }
  }
}
::v-deep .el-card__body {
  padding: 0;
}
.card {
  max-width: 1280px;
  margin: 20px auto;
  // margin: 20px 0 0 0;
  .left {
    width: 32%;
    margin-right: 1.3%;
    min-height: 600px;
    // background: url('../../../../assets/images/jobbg.png') no-repeat;
    background: #f2f5fa;
    background-size: cover;
  }
  .right {
    width: 66.7%;

    min-height: 600px;
    .cardcontent {
      width: 50%;
      height: 200px;
      padding: 24px 32px;
      display: inline-block;
      box-sizing: border-box;
      border: 1px solid #ededed;
    }
  }
}

.employmentService {
  background-size: 100% 100%;
  // height: 600px;
  // background: url('../../../../assets/images/one.png');
}

.navbox {
  .navleft {
    width: 50%;
    ul li {
      padding: 20px;
      border: 1px solid #ededed;
      // background: #fff;
    }
  }
  .navright {
    width: 50%;
    ul li {
      padding: 20px;
      border: 1px solid #ededed;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      // background: #fff;
    }
  }
  .navright {
    width: 50%;
    background: no-repeat;
    width: 100%;
    background-size: 100% 90%;
    height: 500px;
  }
}
.isHover {
  border: 1px solid #f4f7fc;
  box-shadow: 0 10px 20px 0 rgb(33 42 64 / 10%);
  color: #3369ff;
  background: #fff;
}
.coBranding {
  min-height: 276px;
  background: #f2f5fa;
}
.linkage {
  // width: 29.5%;
  width: 48.5%;
  padding: 10px 20px;
  display: flex;
  // font-size: 14px;
  font-size: 18px;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
}
.linkageDiv {
  // width: 135px;
  width: 200px;
  height: 88px;
  // margin: 0 10px 0 0;
  margin: 0 30px 0 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  span {
    line-height: 22px;
    font-size: 14px;
  }
}
.maxCenter {
  max-width: 1280px;
  margin: 20px auto;
}
.flexcenbet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexbet {
  display: flex;
  justify-content: space-between;
}
.title {
  text-align: center;
  padding: 30px 0px 30px 0px;
  width: 100%;
  line-height: 46px;
  font-size: 24px;
  // font-weight: bold;
  font-weight: normal;
  color: #252b3a;
  font-family: Hiragino Sans GB, Helvetica Neue, Helvetica, STHeiTi,
    Microsoft Yahei, sans-serif;
}
.postname {
  font-size: 16px;
  font-weight: 700;
  color: #252b3a;
  letter-spacing: 0;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.postcontent {
  font-size: 14px;
  color: #6d7383;
  height: 40px;
  line-height: 20px;
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.price {
  font-size: 20px;
  color: #fa5731;
  line-height: 32px;
  font-weight: 600;
  margin: 5px 0 0 0;
  span {
    font-size: 12px;
  }
}
.el-tag {
  height: auto;
  padding: 0 8px;
  line-height: 18px;
  font-size: 12px;
  border-radius: 2px;
  box-sizing: border-box;
}
.cardcontent:hover {
  box-shadow: 0 20px 30px rgb(0 0 0 / 10%);
}
// .autoswiper{
//   margin: 20px 0;
// }
.pictrue {
  width: 50%;
}
.deliver {
  color: #a2a2a2;
  font-size: 12px;
  margin-top: 8px;
}
.verticalCard {
  height: 600px;
  width: 100%;
}
.svgicon {
  font-size: 20px;
  color: #3369ff;
  margin-right: 15px;
}
.iconweight {
  font-size: 39px;
  margin-right: 10px;
}
.title span {
  position: relative;
}

.title span:after {
  position: absolute;
  content: " ";
  left: -16px;
  top: 50%;
  margin-top: -10px;
  width: 4px;
  height: 20px;
  background: linear-gradient(135deg, #2791ff 0, #03a7ff 98%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.bgdetails {
  width: 640px;
  height: 415px;
  padding: 20px;
  overflow-y: auto;
  border: 1px solid #ddd;
  margin: 0 0 0 20px;
}
</style>
